import { makeVar } from '@apollo/client';
import { LocationInput, User } from '.';
import { UploadFile } from 'antd/es/upload/interface';
import {
  UserToEdit as TUserToEdit,
  HomepageToEdit as THomepageToEdit,
} from '../pages';
import { Dayjs } from 'dayjs';

export const LoggedInUser = makeVar<User | undefined>(undefined);
export const UserToEdit = makeVar<TUserToEdit | null>(null);
export const StoryToEdit = makeVar<{
  id?: string;
  image?: string;
  video?: string;
  content?: string;
  file?: UploadFile;
  mediaType?: 'image' | 'video';
} | null>(null);
export const GroupToEdit = makeVar<{
  id?: string;
  name?: string;
  description?: string;
  banner?: string;
  file?: UploadFile;
} | null>(null);
export const EventToEdit = makeVar<{
  id?: string;
  title?: string;
  location?: LocationInput;
  start?: null | Dayjs;
  end?: null | Dayjs;
  description?: string;
  banner?: string;
  file?: UploadFile;
} | null>(null);
export const HomepageToEdit = makeVar<THomepageToEdit | null>(null);
export const AuthToken = makeVar<string>('');
