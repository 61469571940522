import axios from '../axios';

export const login = async (
  payload: any
): Promise<{ accessToken: string; refreshToken: string }> => {
  try {
    const endPoint = '/api/auth/login';
    const res = await axios.post(endPoint, payload);

    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error login', err);
    return Promise.reject(err);
  }
};

export const createUser = async (payload: FormData): Promise<any> => {
  try {
    const endPoint = '/api/auth/create-account';
    const res = await axios.post(endPoint, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error createUser', err);
    return Promise.reject(err);
  }
};

export const forgotPassword = async (payload: FormData): Promise<any> => {
  try {
    const endPoint = '/api/auth/recovery-send-email';
    const res = await axios.post(endPoint, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error forgotPassword', err);
    return Promise.reject(err);
  }
};

export const resetPassword = async (payload: FormData): Promise<any> => {
  try {
    const endPoint = '/api/auth/recovery-change-password';
    const res = await axios.post(endPoint, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error resetPassword', err);
    return Promise.reject(err);
  }
};
