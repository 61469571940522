import { Card, Col, Row } from 'antd';
import { Text } from '../Text';
import { Event } from '../../graphql';
import { format } from 'date-fns';
import { colors } from '../../utils';

type Props = {
  event: Event;
  edit?: boolean;
  selected?: boolean;
  onClick?: (id: string) => void;
};

export default function EventItem({ event, edit, onClick, selected }: Props) {
  return (
    <Card
      onClick={edit ? () => onClick?.(event.id) : undefined}
      className={`card-no-padding ${edit && !selected ? 'hoverable-card' : ''}`}
      style={{
        height: '100%',
        borderRadius: 12,
        ...(selected && { border: `2px solid ${colors.blue6}` }),
        ...(edit && { cursor: 'pointer' }),
      }}
    >
      <Row>
        <Col span={24}>
          <img
            src={event.banner}
            alt={''}
            style={{
              width: '100%',
              aspectRatio: 2,
              objectFit: 'cover',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
        </Col>
        <Col span={24} style={{ padding: '12px 16px', display: 'grid' }}>
          <Text variant={'smNormal'}>
            {!!event?.start
              ? `${format(new Date(Number(event.start)), 'MMM, d')}${
                  !!event.end
                    ? ` - ${format(new Date(Number(event.end)), 'MMM, d')}`
                    : ''
                }`
              : 'May, 20 - May, 23'}
          </Text>
          <Text variant={'baseStrong'} style={{ marginTop: 4 }}>
            {event?.title}
          </Text>
          <Text variant={'smMedium'} color={'black6'}>
            {event?.location?.title}
          </Text>
          <Text variant={'smNormal'} color={'black4'} style={{ marginTop: 6 }}>
            {`${event.membersCount} members are going`}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
