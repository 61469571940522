import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($userData: LoginInput!) {
    login(userData: $userData) {
      accessToken
      refreshToken
      user {
        id
        firstName
        lastName
        email
        role
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const REGISTER = gql`
  mutation Register($userData: RegisterInput!) {
    register(userData: $userData)
  }
`;

export const MANUALLY_VERIFY_USER = gql`
  mutation manuallyVerifyUser($userId: String!) {
    manuallyVerifyUser(userId: $userId)
  }
`;

export const UPDATE_USER_INFORMATION = gql`
  mutation updateUserInformation(
    $userId: String!
    $update: UpdateUserAdminInput!
  ) {
    updateUserInformation(userId: $userId, update: $update)
  }
`;

export const APPROVE_USER = gql`
  mutation approveUser($userId: String!) {
    approveUser(userId: $userId)
  }
`;

export const REJECT_USER = gql`
  mutation rejectUser($userId: String!, $type: String!, $reason: String!) {
    rejectUser(userId: $userId, type: $type, reason: $reason)
  }
`;

export const DELETE_USER = gql`
  mutation permanentlyDeleteUser($userId: String!) {
    permanentlyDeleteUser(userId: $userId)
  }
`;

export const UPDATE_STORY = gql`
  mutation updateStory($storyId: String!, $update: UpdateStoryInput!) {
    updateStory(storyId: $storyId, update: $update)
  }
`;

export const UPDATE_STORY_STATUS = gql`
  mutation updateStoryStatus($storyId: String!, $status: String!) {
    updateStoryStatus(storyId: $storyId, status: $status)
  }
`;

export const FEATURE_HOMEPAGE = gql`
  mutation featureHomepage($featuredItems: FeatureItemsInput!) {
    featureHomepage(featuredItems: $featuredItems)
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($groupId: String!, $update: UpdateGroupInput!) {
    updateGroup(groupId: $groupId, update: $update)
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($eventId: String!, $update: UpdateEventInput!) {
    updateEvent(eventId: $eventId, update: $update)
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;
