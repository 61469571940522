import { useCallback, useEffect } from 'react';
import { Col, message, Row } from 'antd';
import {
  PrimaryButton,
  ProgressButton,
  SecondaryButton,
} from '../../../../components';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  GET_STORIES,
  GET_STORY_BY_ID,
  Mutation,
  MutationUpdateStoryArgs,
  MutationUpdateStoryStatusArgs,
  MutationUploadFileArgs,
  Story,
  StoryStatus,
  StoryToEdit,
  UPDATE_STORY,
  UPDATE_STORY_STATUS,
  UPLOAD_FILE,
  uploadClient,
} from '../../../../graphql';
import { startCase } from 'lodash';

type Props = { story: Story };

const StoryActions = ({ story }: Props) => {
  const storyToEdit = useReactiveVar(StoryToEdit);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [updateStory, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateStoryArgs
  >(UPDATE_STORY);
  const [updateStoryStatus, { loading: updatingStatus }] = useMutation<
    Mutation,
    MutationUpdateStoryStatusArgs
  >(UPDATE_STORY_STATUS);

  useEffect(() => {
    return () => {
      if (!!storyToEdit) handleCancelEdit();
    };
  }, []);

  const handleEdit = () => {
    StoryToEdit({ id: story.id });
  };

  const handleCancelEdit = () => {
    StoryToEdit(null);
  };

  const handleUpdateStatus = (status: StoryStatus) => {
    updateStoryStatus({
      variables: {
        storyId: story?.id,
        status,
      },
      refetchQueries: [
        { query: GET_STORIES },
        { query: GET_STORY_BY_ID, variables: { storyId: story.id } },
      ],
    }).then(() => {
      message.success(`Story has been ${startCase(status.toLowerCase())}!`);
    });
  };

  const handleUpdate = (values?: { image?: string; video?: string }) => {
    updateStory({
      variables: {
        storyId: story?.id,
        update: {
          ...values,
          ...(storyToEdit?.content &&
            storyToEdit.content !== story.content && {
              content: storyToEdit.content,
            }),
        },
      },
      refetchQueries: [
        { query: GET_STORIES },
        { query: GET_STORY_BY_ID, variables: { storyId: story.id } },
      ],
    }).then(() => {
      handleCancelEdit();
      message.success('Story has been updated!');
    });
  };

  const handleSaveEdit = () => {
    if (!!storyToEdit?.file) {
      uploadFile({ variables: { file: storyToEdit.file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleUpdate({ [storyToEdit.mediaType as string]: fileString });
      });
    } else {
      handleUpdate();
    }
  };

  const standardButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleEdit}>
            Edit story content
          </SecondaryButton>
        </Col>
        <Col>
          {story.status === StoryStatus.Archived ? (
            <PrimaryButton
              onClick={() => handleUpdateStatus(StoryStatus.Published)}
              loading={updatingStatus}
            >
              Publish story
            </PrimaryButton>
          ) : (
            <ProgressButton
              onClick={() => handleUpdateStatus(StoryStatus.Archived)}
              loading={updatingStatus}
            >
              Archive story
            </ProgressButton>
          )}
        </Col>
      </Row>
    );
  };

  const editButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleCancelEdit}>Cancel</SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            onClick={handleSaveEdit}
            loading={uploading || updating}
          >
            Save changes
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const getButtons = useCallback(() => {
    if (!!storyToEdit?.id) {
      return editButtons();
    }
    return standardButtons();
  }, [story, storyToEdit]);

  return !!story ? <Row>{getButtons()}</Row> : null;
};

export default StoryActions;
