import { useState } from 'react';
import { Col, message, Row, Spin, Tag } from 'antd';
import { ErrorButton, PrimaryButton, Text } from '../../../../components';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationApproveUserArgs,
  User,
  APPROVE_USER,
} from '../../../../graphql';
import { statusMap } from '../../../../utils';
import { RejectUserModal, RejectionReason } from '.';

type Props = { user: User; refetchUsers: () => void };

const UserStatusCell = ({ user, refetchUsers }: Props) => {
  const [reject, setReject] = useState<'form' | 'view' | null>(null);
  const [approveUser, { loading }] = useMutation<
    Mutation,
    MutationApproveUserArgs
  >(APPROVE_USER);

  const handleApprove = () => {
    approveUser({
      variables: {
        userId: user.id,
      },
    }).then(() => {
      message.success('User has been approved!');
      refetchUsers();
    });
  };

  return (
    <>
      {user.status === 'PENDING_APPROVAL' ? (
        loading ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col>
              <PrimaryButton height={34} fontSize={14} onClick={handleApprove}>
                Approve
              </PrimaryButton>
            </Col>
            <Col>
              <ErrorButton
                height={34}
                fontSize={14}
                onClick={() => setReject('form')}
              >
                Reject
              </ErrorButton>
            </Col>
          </Row>
        )
      ) : (
        <Row gutter={10}>
          <Col>
            <Tag color={statusMap[user.status]?.color}>
              {statusMap[user.status]?.label}
            </Tag>
          </Col>
          {['REJECTED', 'BLOCKED'].includes(user.status) && (
            <Col className={'clickable'} onClick={() => setReject('view')}>
              <Text fontSize={12} fontWeight={500} color={'black5'}>
                Why?
              </Text>
            </Col>
          )}
        </Row>
      )}
      {reject === 'form' && (
        <RejectUserModal
          handleClose={() => setReject(null)}
          refetchUsers={refetchUsers}
          type={'REJECT'}
          userId={user.id}
        />
      )}
      {reject === 'view' && (
        <RejectionReason
          handleClose={() => setReject(null)}
          type={user.status === 'REJECTED' ? 'REJECT' : 'BLOCK'}
          reason={user.rejectionReason as string}
        />
      )}
    </>
  );
};

export default UserStatusCell;
