import { UserStatus } from '../graphql';

export type Color = keyof typeof colors;

export const colors = {
  primary: '#3CA6B9',
  primary1: '#3CA6B9',
  primary3: '#3CA6B9',
  primary5: '#3CA6B9BB',
  primary6: '#3CA6B9',

  black1: '#F2F2F5',
  black2: '#CECED9',
  black3: '#B6B6BF',
  black4: '#9D9DA6',
  black5: '#85858C',
  black6: '#5C5C5C',
  black7: '#B0B0BA',
  black8: '#3D3D40',
  black10: '#0D0D0D',

  blue1: '#EFF8F9',
  blue2: '#D8EDF1',
  blue3: '#B1DBE3',
  blue4: '#8ACAD5',
  blue5: '#63B8C7',
  blue6: '#3CA6B9',
  blue7: '#3490A1',
  blue8: '#2C7987',
  blue9: '#24626E',
  blue10: '#1B4C54',

  Cyan7: '#08979C',

  red5: '#FF4D4F',
  red6: '#F5222D',

  orange6: '#FA8C16',

  colorWhite: '#FFFFFF',

  defaultColor: '#0D0D0D',
  defaultBorderColor: '#D9D9D9',
  defaultBg: '#FFFFFF',

  background: '#EFF8F9',
  darkBackground: '#0C0D0E',
  colorItemText: '#B3B3B3',
  colorTextLightSolid: '#FFFFFF',
  colorTextPlaceholder: '#5C5C5C',
  colorIcon: '#6E6E6E',
  borderColor: '#D9D9D9',

  //App Colors
  black: '#000',
  white: '#FFF',

  primary50: '#FCFEFF',
  primary100: '#F5FAFF',
  primary300: '#A3D3FF',
  primary500: '#0086FF',
  primary600: '#006BCC',
  primary700: '#005099',
  primary800: '#003666',
  primary900: '#001B33',

  success: '#50B17F',
  successAccent: '#E9FDF2',
  error: '#EA332D',
  errorAccent: '#FDF6E9',
  warning: '#F2AB3C',
  warningAccent: '#FBE9E9',
  progress: '#D87A16',

  //secondary
  secondary: '#050505',
  secondary50: '#F1F2FC',
  secondary100: '#DDDEE8',
  secondary200: '#CCCDD6',
  secondary300: '#B6B7BF',
  secondary400: '#9B9CA3',
  secondary500: '#7C7C82',
  secondary600: '#37373C',
  secondary700: '#252527',
  secondary800: '#181819',
  secondary900: '#050505',
};

export const breakpoints = {
  screenXS: 395,
  screenSM: 600,
  screenMD: 960,
  screenLG: 1280,
  screenXL: 1440,
  screenXXL: 1920,
};

export const statusMap = {
  [UserStatus.PendingVerification]: {
    label: 'Verification email sent',
    color: 'orange',
  },
  [UserStatus.Verified]: {
    label: 'Profile to complete',
    color: 'orange',
  },
  [UserStatus.Approved]: { label: 'Approved', color: 'blue' },
  [UserStatus.Rejected]: { label: 'Rejected', color: 'error' },
  [UserStatus.Blocked]: { label: 'Blocked', color: 'error' },
  [UserStatus.PendingApproval]: { label: 'Review', color: 'orange' },
};

export const storytellerQuestions = {
  background:
    'Share the origin of your storytelling journey. What pivotal moment sparked your passion for sharing ocean-related stories?',
  memorableInfluences:
    'Reflect on specific memories from your upbringing that profoundly influenced your connection to the ocean. How have these experiences shaped your storytelling?',
  professionalPathways:
    'What professional paths have you pursued since leaving school, and how have they contributed to your growth as a storyteller?',
  currentInspirations:
    'Who or what serves as your current source of inspiration in the world of ocean storytelling?',
  ongoingProjects:
    'Briefly describe the projects or stories you’re currently working on. What themes or messages are you aiming to convey?',
  impactfulChanges:
    'If given the chance, what positive change would you most like to bring about in the world or the environment through your storytelling?',
  influentialMedia:
    'Are there any books, documentaries, or other media that have significantly influenced your thinking as an ocean storyteller? Feel free to recommend them to fellow ocean lovers.',
  legacyAspirations:
    'What legacy do you hope your storytelling leaves behind? How would you like to be remembered?',
  guidingAdvice:
    'For aspiring ocean storytellers, what advice would you offer to help them pursue their dreams and make a meaningful impact?',
};

export const guardianQuestions = {
  missionPassion:
    'Briefly outline your organisation’s mission and commitment to ocean conservation. What drives your passion for safeguarding the oceans?',
  guidingValues:
    'Share the foundational values that guide your organisation’s work in protecting and preserving the oceans.',
  impactfulContributions:
    'Describe the positive impact your organisation aims to achieve in the realm of conservation and ocean protection.',
  conservationInitiatives:
    'Highlight the primary conservation initiatives your organisation is currently involved in. How do these initiatives contribute to ocean sustainability?',
  inspirationalInfluences:
    'Are there specific individuals, organisations, or brands within the ocean conservation community that inspire your work? How have they influenced your approach to advocacy?',
  futureLegacy:
    'What lasting legacy does your organisation aspire to leave in the realm of ocean conservation, and how do you plan to achieve it?',
  advocacyForChange:
    'If your organisation could advocate for one critical change in the world or environment today, what would it be, and how do you envision achieving it?',
  mediaPerspectives:
    'Share any influential books, documentaries, or media that have shaped your organisation’s perspective on ocean conservation.',
  storytellingRole:
    'How does storytelling contribute to your advocacy for ocean conservation? In what ways has storytelling proven to be impactful for your cause?',
  storytellingFundraising:
    'How important is fundraising through storytelling for your advocacy efforts? Are there specific projects or initiatives for which you would like storytellers to contribute to fundraising?',
  guidanceForOthers:
    'For other charities, organisations, or brands looking to join the cause of ocean conservation, what advice or guidance would you offer based on your own experiences?',
};

export const advocateQuestions = {
  localBeach: 'Specify your local beach.',
  inspirations:
    'What/Who currently inspires you? (Share any influential books, documentaries, or media that have shaped your perspective on ocean conservation).',
  coreValues: 'What do you value most in life?',
  environmentalChange:
    'What would you most like to change in the world/environment today?',
  contributions:
    'What would you say you do to help restore health to our oceans?',
  criticalAdvocacyChange:
    'If you could advocate for one critical change in the world or environment today, what would it be, and how do you envision achieving it?',
};

export const ambassadorQuestions = {
  school: 'Which school are you at?',
  oceanDiscovery: 'How did you become a Junior Ocean Ambassador?',
  educationalPursuits:
    'As a young advocate, how do you engage in educational activities related to the oceans? What topics fascinate you the most?',
  inspirationalFigures:
    'Who in the ocean conservation community inspires you? How do they influence your commitment to protecting the oceans?',
  youthInitiatives:
    'Describe any initiatives or projects you’ve undertaken to promote ocean awareness among your peers.',
  environmentalChange:
    'If you could make one positive change for the environment today, what would it be, and how would you go about achieving it?',
  favOceanReads:
    'Any favourite books, documentaries, or educational materials about the oceans that you’d recommend to fellow young ocean enthusiasts?',
  futureLegacy:
    'What positive impact do you hope to make as a Junior Ocean Ambassador, and what legacy do you aspire to leave for future generations?',
  guidance:
    'If you could give advice to other young individuals interested in ocean advocacy, what would it be?',
};

export const advocateActivities = [
  'Surfing',
  'Free-diving',
  'Scuba Diving',
  'Sea Swimming',
  'Kayak, Canoe',
  'Lifesaving',
  'Education',
  'Beach Cleanup',
  'Kite-surfing',
];
