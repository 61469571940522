import { Col, message, Modal, Row } from 'antd';
import { ErrorButton, SecondaryButton, Text } from '../../../../components';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationPermanentlyDeleteUserArgs,
  DELETE_USER,
  GET_ALL_USERS,
} from '../../../../graphql';
import { useNavigate } from 'react-router-dom';

type Props = {
  handleClose: () => void;
  userId: string;
};

const RejectUserModal = ({ handleClose, userId }: Props) => {
  const navigate = useNavigate();
  const [deleteUser, { loading }] = useMutation<
    Mutation,
    MutationPermanentlyDeleteUserArgs
  >(DELETE_USER);

  const handleDelete = () => {
    deleteUser({
      variables: {
        userId,
      },
      refetchQueries: [{ query: GET_ALL_USERS }],
    }).then(() => {
      message.success('User has been permanently deleted!');
      handleClose();
      navigate(-1);
    });
  };

  return (
    <Modal open centered title={null} footer={null} onCancel={handleClose}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500} color={'black10'}>
            Delete user
          </Text>
        </Col>
        <Col span={24}>
          <Text color={'black5'} style={{ display: 'flex', width: '90%' }}>
            Are you sure you want to delete this user permanently? This will
            delete all user related data (stories, likes, comments, etc.)
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={12} justify={'end'}>
            <Col>
              <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            </Col>
            <Col>
              <ErrorButton loading={loading} onClick={handleDelete}>
                Confirm
              </ErrorButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default RejectUserModal;
