export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date custom scalar type */
  Date: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  sender: User;
  conversation: Conversation;
  createdAt: Scalars['String']['output'];
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID']['output'];
  participants: Array<User>;
  messages: Array<ChatMessage>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Like = {
  __typename?: 'Like';
  id: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
  story: Story;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID']['output'];
  comment: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
  story: Story;
};

export type GroupUser = {
  __typename?: 'GroupUser';
  id: Scalars['ID']['output'];
  user: User;
  group: Group;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  description: Scalars['String']['output'];
  banner: Scalars['String']['output'];
  status: GroupStatus;
  featured?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  owner: User;
  members: Array<GroupUser>;
  stories: Array<Story>;
  membersCount?: Maybe<Scalars['Float']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  media?: Maybe<Array<Media>>;
};

export enum GroupStatus {
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED',
}

export type Media = {
  __typename?: 'Media';
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type EventUser = {
  __typename?: 'EventUser';
  id: Scalars['ID']['output'];
  user: User;
  event: Event;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Location = {
  __typename?: 'Location';
  placeId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  description: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
  locationString: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  description: Scalars['String']['output'];
  banner: Scalars['String']['output'];
  start: Scalars['String']['output'];
  end?: Maybe<Scalars['String']['output']>;
  location: Location;
  status: EventStatus;
  featured?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  owner: User;
  members: Array<EventUser>;
  stories: Array<Story>;
  membersCount?: Maybe<Scalars['Float']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
};

export enum EventStatus {
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED',
}

export type Story = {
  __typename?: 'Story';
  id: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  status: StoryStatus;
  type: StoryType;
  featured?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
  group: Group;
  event: Event;
  likes: Array<Like>;
  comments: Array<Comment>;
};

export enum StoryStatus {
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED',
}

export enum StoryType {
  Feed = 'FEED',
  Group = 'GROUP',
  Event = 'EVENT',
}

export type Storyteller = {
  __typename?: 'Storyteller';
  id: Scalars['ID']['output'];
  background?: Maybe<Scalars['String']['output']>;
  memorableInfluences?: Maybe<Scalars['String']['output']>;
  professionalPathways?: Maybe<Scalars['String']['output']>;
  currentInspirations?: Maybe<Scalars['String']['output']>;
  ongoingProjects?: Maybe<Scalars['String']['output']>;
  impactfulChanges?: Maybe<Scalars['String']['output']>;
  influentialMedia?: Maybe<Scalars['String']['output']>;
  legacyAspirations?: Maybe<Scalars['String']['output']>;
  guidingAdvice?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  videoUrls?: Maybe<Array<Scalars['String']['output']>>;
  template?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  profile: Profile;
};

export type Guardian = {
  __typename?: 'Guardian';
  id: Scalars['ID']['output'];
  missionPassion?: Maybe<Scalars['String']['output']>;
  guidingValues?: Maybe<Scalars['String']['output']>;
  impactfulContributions?: Maybe<Scalars['String']['output']>;
  conservationInitiatives?: Maybe<Scalars['String']['output']>;
  inspirationalInfluences?: Maybe<Scalars['String']['output']>;
  futureLegacy?: Maybe<Scalars['String']['output']>;
  advocacyForChange?: Maybe<Scalars['String']['output']>;
  mediaPerspectives?: Maybe<Scalars['String']['output']>;
  storytellingRole?: Maybe<Scalars['String']['output']>;
  storytellingFundraising?: Maybe<Scalars['String']['output']>;
  guidanceForOthers?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  profile: Profile;
};

export type Advocate = {
  __typename?: 'Advocate';
  id: Scalars['ID']['output'];
  localBeach?: Maybe<Scalars['String']['output']>;
  inspirations?: Maybe<Scalars['String']['output']>;
  coreValues?: Maybe<Scalars['String']['output']>;
  environmentalChange?: Maybe<Scalars['String']['output']>;
  contributions?: Maybe<Scalars['String']['output']>;
  criticalAdvocacyChange?: Maybe<Scalars['String']['output']>;
  activities?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  profile: Profile;
};

export type Ambassador = {
  __typename?: 'Ambassador';
  id: Scalars['ID']['output'];
  school?: Maybe<Scalars['String']['output']>;
  oceanDiscovery?: Maybe<Scalars['String']['output']>;
  educationalPursuits?: Maybe<Scalars['String']['output']>;
  inspirationalFigures?: Maybe<Scalars['String']['output']>;
  youthInitiatives?: Maybe<Scalars['String']['output']>;
  environmentalChange?: Maybe<Scalars['String']['output']>;
  favOceanReads?: Maybe<Scalars['String']['output']>;
  futureLegacy?: Maybe<Scalars['String']['output']>;
  guidance?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  profile: Profile;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['ID']['output'];
  bio?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['String']['output']>;
  long?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  banner?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  instagramUrl?: Maybe<Scalars['String']['output']>;
  xUrl?: Maybe<Scalars['String']['output']>;
  behanceUrl?: Maybe<Scalars['String']['output']>;
  linkedInUrl?: Maybe<Scalars['String']['output']>;
  tiktokUrl?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
  storyteller?: Maybe<Storyteller>;
  guardian?: Maybe<Guardian>;
  advocate?: Maybe<Advocate>;
  ambassador?: Maybe<Ambassador>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  role: UserRole;
  status: UserStatus;
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  stories: Array<Story>;
  profile?: Maybe<Profile>;
  messages?: Maybe<Array<ChatMessage>>;
  groups: Array<GroupUser>;
  events: Array<EventUser>;
  distance?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  conversations: Array<Conversation>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Storyteller = 'STORYTELLER',
  Guardian = 'GUARDIAN',
  Advocate = 'ADVOCATE',
  Ambassador = 'AMBASSADOR',
}

export enum UserStatus {
  PendingVerification = 'PENDING_VERIFICATION',
  Verified = 'VERIFIED',
  PendingApproval = 'PENDING_APPROVAL',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Blocked = 'BLOCKED',
}

export type TokenType = {
  __typename?: 'TokenType';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type Hero = {
  __typename?: 'Hero';
  heading: Scalars['String']['output'];
  description: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
};

/** Paginated Users */
export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  data: Array<User>;
  count: Scalars['Float']['output'];
};

/** Paginated Stories */
export type PaginatedStories = {
  __typename?: 'PaginatedStories';
  data: Array<Story>;
  count: Scalars['Float']['output'];
};

/** Featured homepage items */
export type FeaturedItems = {
  __typename?: 'FeaturedItems';
  hero: Hero;
  stories: Array<Story>;
  groups: Array<Group>;
  events: Array<Event>;
};

export type City = {
  __typename?: 'City';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

/** Paginated Groups */
export type PaginatedGroups = {
  __typename?: 'PaginatedGroups';
  data: Array<Group>;
  count: Scalars['Float']['output'];
};

/** Paginated Group Members */
export type PaginatedGroupMembers = {
  __typename?: 'PaginatedGroupMembers';
  data: Array<GroupUser>;
  count: Scalars['Float']['output'];
};

/** Paginated Events */
export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  data: Array<Event>;
  count: Scalars['Float']['output'];
};

/** Paginated Event Members */
export type PaginatedEventMembers = {
  __typename?: 'PaginatedEventMembers';
  data: Array<EventUser>;
  count: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  ping: Scalars['String']['output'];
  getUsers: PaginatedUsers;
  getUser: User;
  getUserById: User;
  getUsersByDistance: PaginatedUsers;
  getStories: PaginatedStories;
  getStoriesByUser: PaginatedStories;
  getStoriesByGroup: PaginatedStories;
  getStoriesByEvent: PaginatedStories;
  getStoryById: Story;
  getStoryLikes: Array<Like>;
  getStoryComments: Array<Comment>;
  getFeaturedItems: FeaturedItems;
  getGroupById: Group;
  getGroups: PaginatedGroups;
  getUserGroups: PaginatedGroups;
  getGroupMembers: PaginatedGroupMembers;
  getEventById: Event;
  getEvents: PaginatedEvents;
  getUserEvents: PaginatedEvents;
  getEventMembers: PaginatedEventMembers;
  getCountries: Array<Country>;
  getCitiesByCountry: Array<City>;
  conversationsWithMessages?: Maybe<Conversation>;
  getConversation?: Maybe<Conversation>;
};

export type QueryGetUsersArgs = {
  exclude?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
  status?: InputMaybe<Array<UserStatus>>;
};

export type QueryGetUserByIdArgs = {
  userId: Scalars['String']['input'];
};

export type QueryGetUsersByDistanceArgs = {
  take: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryGetStoriesArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetStoriesByUserArgs = {
  userId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetStoriesByGroupArgs = {
  groupId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetStoriesByEventArgs = {
  eventId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetStoryByIdArgs = {
  storyId: Scalars['String']['input'];
};

export type QueryGetStoryLikesArgs = {
  storyId: Scalars['String']['input'];
};

export type QueryGetStoryCommentsArgs = {
  storyId: Scalars['String']['input'];
};

export type QueryGetGroupByIdArgs = {
  groupId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetGroupsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  exclude?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUserGroupsArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetGroupMembersArgs = {
  groupId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetEventByIdArgs = {
  eventId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetEventsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUserEventsArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetEventMembersArgs = {
  eventId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetCitiesByCountryArgs = {
  country: Scalars['String']['input'];
};

export type QueryConversationsWithMessagesArgs = {
  conversationId: Scalars['Int']['input'];
};

export type QueryGetConversationArgs = {
  conversationId: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  registerAdmin: Scalars['Boolean']['output'];
  registerAdminProfile: Scalars['Boolean']['output'];
  register: Scalars['Boolean']['output'];
  login: LoginResponse;
  validateUserEmail: Scalars['Boolean']['output'];
  manuallyVerifyUser: Scalars['Boolean']['output'];
  forgotPassword: Scalars['Boolean']['output'];
  validateResetPassword: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  refreshAccessToken: TokenType;
  updateUser: Scalars['Boolean']['output'];
  updateUserInformation: Scalars['Boolean']['output'];
  approveUser: Scalars['Boolean']['output'];
  rejectUser: Scalars['Boolean']['output'];
  permanentlyDeleteUser: Scalars['Boolean']['output'];
  createStory: Scalars['Boolean']['output'];
  createGroupStory: Scalars['Boolean']['output'];
  createEventStory: Scalars['Boolean']['output'];
  updateStory: Scalars['Boolean']['output'];
  updateStoryStatus: Scalars['Boolean']['output'];
  deleteStory: Scalars['Boolean']['output'];
  likeStory: Scalars['Boolean']['output'];
  addComment: Scalars['Boolean']['output'];
  updateComment: Scalars['Boolean']['output'];
  deleteComment: Scalars['Boolean']['output'];
  featureHomepage: Scalars['Boolean']['output'];
  createGroup: Scalars['Boolean']['output'];
  updateGroup: Scalars['Boolean']['output'];
  deleteGroup: Scalars['Boolean']['output'];
  joinGroup: Scalars['Boolean']['output'];
  leaveGroup: Scalars['Boolean']['output'];
  createEvent: Scalars['Boolean']['output'];
  updateEvent: Scalars['Boolean']['output'];
  deleteEvent: Scalars['Boolean']['output'];
  joinEvent: Scalars['Boolean']['output'];
  leaveEvent: Scalars['Boolean']['output'];
  uploadFile: Scalars['String']['output'];
  uploadMultipleFiles: Array<Scalars['String']['output']>;
  createConversation: Conversation;
  sendMessage: ChatMessage;
};

export type MutationRegisterAdminArgs = {
  userData: RegisterInput;
};

export type MutationRegisterArgs = {
  userData: RegisterInput;
};

export type MutationLoginArgs = {
  userData: LoginInput;
};

export type MutationValidateUserEmailArgs = {
  verificationId: Scalars['String']['input'];
};

export type MutationManuallyVerifyUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationValidateResetPasswordArgs = {
  verificationId: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  verificationId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationUpdateUserArgs = {
  userUpdate: UpdateUserInput;
  forReview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateUserInformationArgs = {
  userId: Scalars['String']['input'];
  update: UpdateUserAdminInput;
};

export type MutationApproveUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationRejectUserArgs = {
  userId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type MutationPermanentlyDeleteUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationCreateStoryArgs = {
  story: CreateStoryInput;
};

export type MutationCreateGroupStoryArgs = {
  groupId: Scalars['String']['input'];
  story: CreateStoryInput;
};

export type MutationCreateEventStoryArgs = {
  eventId: Scalars['String']['input'];
  story: CreateStoryInput;
};

export type MutationUpdateStoryArgs = {
  storyId: Scalars['String']['input'];
  update: UpdateStoryInput;
};

export type MutationUpdateStoryStatusArgs = {
  storyId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type MutationDeleteStoryArgs = {
  storyId: Scalars['String']['input'];
};

export type MutationLikeStoryArgs = {
  storyId: Scalars['String']['input'];
  like: Scalars['Boolean']['input'];
};

export type MutationAddCommentArgs = {
  storyId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
};

export type MutationUpdateCommentArgs = {
  commentId: Scalars['String']['input'];
  update: Scalars['String']['input'];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars['String']['input'];
};

export type MutationFeatureHomepageArgs = {
  featuredItems: FeatureItemsInput;
};

export type MutationCreateGroupArgs = {
  group: CreateGroupInput;
};

export type MutationUpdateGroupArgs = {
  groupId: Scalars['String']['input'];
  update: UpdateGroupInput;
};

export type MutationDeleteGroupArgs = {
  groupId: Scalars['String']['input'];
};

export type MutationJoinGroupArgs = {
  groupId: Scalars['String']['input'];
};

export type MutationLeaveGroupArgs = {
  groupId: Scalars['String']['input'];
};

export type MutationCreateEventArgs = {
  event: CreateEventInput;
};

export type MutationUpdateEventArgs = {
  eventId: Scalars['String']['input'];
  update: UpdateEventInput;
};

export type MutationDeleteEventArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationJoinEventArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationLeaveEventArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};

export type MutationUploadMultipleFilesArgs = {
  files: Array<Scalars['Upload']['input']>;
};

export type MutationCreateConversationArgs = {
  participantIds: Array<Scalars['String']['input']>;
};

export type MutationSendMessageArgs = {
  createMessageInput: CreateMessageInput;
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: UserRole;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<UpdateProfileInput>;
};

export type UpdateProfileInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['String']['input']>;
  long?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  banner?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  xUrl?: InputMaybe<Scalars['String']['input']>;
  behanceUrl?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  tiktokUrl?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
  storyteller?: InputMaybe<UpdateStorytellerInput>;
  guardian?: InputMaybe<UpdateGuardianInput>;
  advocate?: InputMaybe<UpdateAdvocateInput>;
  ambassador?: InputMaybe<UpdateAmbassadorInput>;
};

export type UpdateStorytellerInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  memorableInfluences?: InputMaybe<Scalars['String']['input']>;
  professionalPathways?: InputMaybe<Scalars['String']['input']>;
  currentInspirations?: InputMaybe<Scalars['String']['input']>;
  ongoingProjects?: InputMaybe<Scalars['String']['input']>;
  impactfulChanges?: InputMaybe<Scalars['String']['input']>;
  influentialMedia?: InputMaybe<Scalars['String']['input']>;
  legacyAspirations?: InputMaybe<Scalars['String']['input']>;
  guidingAdvice?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  template?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGuardianInput = {
  missionPassion?: InputMaybe<Scalars['String']['input']>;
  guidingValues?: InputMaybe<Scalars['String']['input']>;
  impactfulContributions?: InputMaybe<Scalars['String']['input']>;
  conservationInitiatives?: InputMaybe<Scalars['String']['input']>;
  inspirationalInfluences?: InputMaybe<Scalars['String']['input']>;
  futureLegacy?: InputMaybe<Scalars['String']['input']>;
  advocacyForChange?: InputMaybe<Scalars['String']['input']>;
  mediaPerspectives?: InputMaybe<Scalars['String']['input']>;
  storytellingRole?: InputMaybe<Scalars['String']['input']>;
  storytellingFundraising?: InputMaybe<Scalars['String']['input']>;
  guidanceForOthers?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdvocateInput = {
  localBeach?: InputMaybe<Scalars['String']['input']>;
  inspirations?: InputMaybe<Scalars['String']['input']>;
  coreValues?: InputMaybe<Scalars['String']['input']>;
  environmentalChange?: InputMaybe<Scalars['String']['input']>;
  contributions?: InputMaybe<Scalars['String']['input']>;
  criticalAdvocacyChange?: InputMaybe<Scalars['String']['input']>;
  activities?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAmbassadorInput = {
  school?: InputMaybe<Scalars['String']['input']>;
  oceanDiscovery?: InputMaybe<Scalars['String']['input']>;
  educationalPursuits?: InputMaybe<Scalars['String']['input']>;
  inspirationalFigures?: InputMaybe<Scalars['String']['input']>;
  youthInitiatives?: InputMaybe<Scalars['String']['input']>;
  environmentalChange?: InputMaybe<Scalars['String']['input']>;
  favOceanReads?: InputMaybe<Scalars['String']['input']>;
  futureLegacy?: InputMaybe<Scalars['String']['input']>;
  guidance?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserAdminInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<UpdateProfileInput>;
  role?: InputMaybe<UserRole>;
};

export type CreateStoryInput = {
  content: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  type: StoryType;
};

export type UpdateStoryInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureItemsInput = {
  hero?: InputMaybe<UpdateHeroInput>;
  storyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  eventIds?: InputMaybe<Array<Scalars['String']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateHeroInput = {
  heading?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  buttonUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  banner: Scalars['String']['input'];
};

export type UpdateGroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  banner?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GroupStatus>;
};

export type CreateEventInput = {
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  banner: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
};

export type LocationInput = {
  placeId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  locationString: Scalars['String']['input'];
};

export type UpdateEventInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  banner?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  start?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EventStatus>;
};

export type CreateMessageInput = {
  conversationId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  messageAdded: ChatMessage;
};

export type SubscriptionMessageAddedArgs = {
  conversationId: Scalars['Int']['input'];
};
