import React, { useState } from 'react';
import { PrimaryButton, Text } from '../../components';
import { Input, Form, Row, Col, message } from 'antd';
import AuthLayout from './AuthLayout';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../api';

type FieldType = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: FieldType) => {
    setLoading(true);
    const data = new FormData();
    data.append('password', values.password);

    navigate('/');
    resetPassword(data)
      .then(() => {
        setLoading(false);
        message.success('Password has been reset!');
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
        console.log('err login', err);
      });
  };

  return (
    <AuthLayout flip>
      <Form
        name="basic"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Text fontSize={24} fontWeight={600}>
              Set a new password
            </Text>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="password"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input.Password required placeholder="New password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(''));
                      },
                    }),
                  ]}
                >
                  <Input.Password required placeholder="Confirm new password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <PrimaryButton
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    marginTop: '0.5em',
                  }}
                >
                  Reset password
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default ResetPassword;
