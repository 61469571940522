import React, { ForwardRefExoticComponent, useEffect, useState } from 'react';
import { Card, Col, Divider, Layout, Menu, Progress, Row, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../utils';
import { Text } from './Text';
import {
  EditOutlined,
  FileImageOutlined,
  FolderOutlined,
  HomeOutlined,
  LogoutOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { logout, toggleSidebar } from '../redux/reducers';
import { store } from '../redux/store';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useDispatch } from 'react-redux';

type Item = {
  key: string;
  title: string;
  to: string;
  icon: ForwardRefExoticComponent<Pick<any, any>>;
  isButton?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  to?: string | undefined,
  icon?: React.ReactNode,
  disabled?: boolean,
  onClick?: () => void,
): MenuItem {
  return {
    key,
    icon,
    label:
      to && !disabled ? (
        <Link to={to}>{label}</Link>
      ) : disabled ? (
        <Tooltip title={'Coming soon!'}>{label}</Tooltip>
      ) : (
        label
      ),
    onClick,
    disabled,
  } as MenuItem;
}

const bottomItems: MenuItem[] = [
  getItem(
    'Search',
    'search',
    '/search',
    <SearchOutlined style={{ fontSize: 16 }} />,
    true,
  ),
  getItem(
    'Help',
    'help',
    '/help',
    <QuestionCircleOutlined style={{ fontSize: 16 }} />,
    true,
  ),
  getItem(
    'Logout',
    'logout',
    undefined,
    <LogoutOutlined style={{ fontSize: 16 }} />,
    false,
    () => store.dispatch(logout()),
  ),
];

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();
  const { md, lg, xl } = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>('home');

  useEffect(() => {
    if (location.pathname.includes('collections'))
      setSelectedKey('my-collections');
    else if (location.pathname.includes('create')) setSelectedKey('create');
    else if (location.pathname.includes('creations'))
      setSelectedKey('my-creations');
    else if (location.pathname.includes('dashboard'))
      setSelectedKey('dashboard');
    else if (location.pathname.includes('help')) setSelectedKey('help');
    else if (location.pathname.includes('search')) setSelectedKey('search');
    else setSelectedKey('dashboard');
  }, [location.pathname]);

  const getWidth = () => {
    if (xl) return 325;
    if (lg) return 300;
    return 280;
  };

  const items: Item[] = [
    {
      key: 'dashboard',
      title: 'dashboard',
      to: '/',
      icon: HomeOutlined,
    },
    {
      key: 'create',
      title: 'Create',
      to: '/create',
      icon: PlusOutlined,
      isButton: true,
    },
    {
      key: 'my-creations',
      title: 'My creations',
      to: '/creations',
      icon: FileImageOutlined,
    },
    {
      key: 'my-collections',
      title: 'My collections',
      to: '/collections',
      icon: FolderOutlined,
    },
  ];

  return (
    <Sider
      width={getWidth()}
      collapsible={false}
      style={{
        backgroundColor: colors.darkBackground,
        padding: '2em 0 1em 0',
        borderRadius: '0px 12px 12px 0px',
        height: '100vh',
        position: 'fixed',
        zIndex: 10,
        overflow: 'auto',
      }}
      className={'scroll-container'}
      trigger={null}
    >
      <Row gutter={[0, 20]}>
        <Col span={24} style={{ padding: '5px 20px 10px 20px' }}>
          <Row align={'middle'} justify={'space-between'}>
            <Col className={'clickable'} onClick={() => navigate('/')}>
              <img
                src={require('../assets/images/logo.png')}
                alt={'Ringfence'}
                style={{ width: md ? 197 : 163 }}
              />
            </Col>
            {!md && (
              <Col>
                <img
                  src={require('../assets/images/sidebarClose.png')}
                  alt={'close'}
                  style={{ height: 36 }}
                  className={'clickable'}
                  onClick={() => dispatch(toggleSidebar())}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24} style={{ padding: '0 20px' }}>
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col span={24}>
          <Row gutter={[0, 6]}>
            <Col span={24} style={{ padding: '0 20px' }}>
              <Text color={'black7'}>General</Text>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 12]}>
                {items.map((item) => (
                  <Col
                    span={24}
                    style={{
                      padding: 4,
                      borderRight:
                        selectedKey === item.key && selectedKey !== 'create'
                          ? '3px solid white'
                          : 'none',
                    }}
                  >
                    <Tooltip title={item.disabled ? 'Coming soon' : undefined}>
                      <Row
                        className={
                          !item.isButton && selectedKey !== item.key
                            ? 'menu-item'
                            : ''
                        }
                        align={'middle'}
                        style={{
                          padding: item.isButton ? '8px 16px' : '4px 32px',
                          backgroundColor: item.isButton
                            ? colors.primary
                            : 'transparent',
                          margin: item.isButton ? '0 16px' : 0,
                          borderRadius: 8,
                          cursor: item.disabled ? 'not-allowed' : 'pointer',
                        }}
                        onClick={
                          item.onClick ? item.onClick : () => navigate(item.to)
                        }
                        gutter={14}
                      >
                        <Col>
                          <item.icon
                            style={{
                              fontSize: 16,
                              color: item.isButton
                                ? colors.darkBackground
                                : selectedKey === item.key
                                  ? 'white'
                                  : colors.colorItemText,
                            }}
                          />
                        </Col>
                        <Col>
                          <Text
                            className={item.isButton ? '' : 'menu-text'}
                            fontSize={16}
                            fontWeight={500}
                            color={item.isButton ? 'darkBackground' : undefined}
                          >
                            {item.title}
                          </Text>
                        </Col>
                      </Row>
                    </Tooltip>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[0, 12]} style={{ marginTop: '1em', padding: '0 20px' }}>
        <Col span={24}>
          <Card className={'card-md-padding'}>
            <Row gutter={[0, 2]}>
              <Col span={24}>
                <Text fontWeight={600} fontSize={16}>
                  Used space
                </Text>
              </Col>
              <Col span={24}>
                <Text color={'black8'}>
                  The last generation cost you 4 credits
                </Text>
              </Col>
              <Col span={24}>
                <Text color={'black8'}>
                  No worries, you've got 50 credits left!
                </Text>
              </Col>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Row>
                  <Col span={24}>
                    <Text color={'black8'}>20/70</Text>
                  </Col>
                  <Col span={24}>
                    <Progress
                      showInfo={false}
                      strokeLinecap={'round'}
                      percent={30}
                      trailColor={colors.black5}
                      style={{ height: 10 }}
                      size={['100%', 6]}
                      strokeColor={'white'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            style={{
              backgroundColor: colors.darkBackground,
              fontWeight: '500',
              color: colors.colorItemText,
            }}
            items={bottomItems}
          />
        </Col>
      </Row>
    </Sider>
  );
};

export default Sidebar;
