import { Breadcrumb, Card, Col, ConfigProvider, Input, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Query,
  QueryGetStoryByIdArgs,
  Story,
  GET_STORY_BY_ID,
  StoryToEdit,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import { Text } from '../../../components';
import { startCase } from 'lodash';
import { StoryActions } from '.';
import { colors } from '../../../utils';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/es/upload';

type MediaType = 'image' | 'video';

export default function SingleStory() {
  const { id } = useParams();
  const storyToEdit = useReactiveVar(StoryToEdit);
  const [story, setStory] = useState<Story>();
  const [filePreview, setFilePreview] = useState<any>();
  const [mediaType, setMediaType] = useState<MediaType>('image');
  const { data, loading } = useQuery<Query, QueryGetStoryByIdArgs>(
    GET_STORY_BY_ID,
    {
      variables: { storyId: id ?? '' },
    }
  );

  useEffect(() => {
    if (!!data?.getStoryById) {
      setStory(data.getStoryById);
      if (!!data.getStoryById.image) {
        setMediaType('image');
        setFilePreview(data.getStoryById.image);
      } else {
        setMediaType('video');
        setFilePreview(data.getStoryById.video);
      }
    }
  }, [data]);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/*,video/*',
    // @ts-ignore
    action: (file) => {
      if (file.type.includes('video')) setMediaType('video');
      else setMediaType('image');
      StoryToEdit({
        ...storyToEdit,
        file,
        mediaType: file.type.includes('video') ? 'video' : 'image',
      });
      previewImage(file);
    },
    showUploadList: false,
  };

  const previewImage = (file: any) => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      setFilePreview(oFREvent?.target?.result);
    };
  };

  return loading ? (
    <Row justify={'center'} style={{ padding: '5em 3em' }}>
      <Spin size={'large'} />
    </Row>
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
            activeBorderColor: colors.defaultBorderColor,
            hoverBg: colors.white,
            activeBg: colors.white,
          },
          Select: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
          },
        },
      }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                  <Col>
                    <Breadcrumb>
                      <Breadcrumb.Item>All stories</Breadcrumb.Item>
                      <Breadcrumb.Item>
                        {story?.user.firstName + ' ' + story?.user.lastName}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col>
                    <StoryActions story={story as Story} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: 12 }}>
                <Text fontSize={16} fontWeight={600}>
                  Account information
                </Text>
              </Col>
              <Col md={24} lg={12} style={{ marginTop: 10 }}>
                <Row gutter={[16, 20]}>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      Full name
                    </Text>
                    <Text fontSize={16}>
                      {story?.user.firstName + ' ' + story?.user.lastName}
                    </Text>
                  </Col>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      Email
                    </Text>
                    <Text fontSize={16}>{story?.user.email}</Text>
                  </Col>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      User role
                    </Text>
                    <Text fontSize={16}>
                      {startCase(story?.user.role.toLowerCase())}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Text fontSize={16} fontWeight={600}>
                  Story
                </Text>
              </Col>
              <Col md={24} style={{ marginTop: 10 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row gutter={[8, 4]}>
                      <Col span={24}>
                        <Text fontSize={12} color={'black5'}>
                          {!!story?.image ? 'Image' : 'Video'}
                        </Text>
                      </Col>
                      <Col span={12}>
                        {!!storyToEdit?.id ? (
                          <Dragger
                            {...uploadProps}
                            style={{ padding: '0 8px' }}
                          >
                            {filePreview &&
                              (mediaType === 'image' ? (
                                <img
                                  id="blah"
                                  src={filePreview}
                                  alt=""
                                  style={{
                                    height: 300,
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                    borderRadius: 4,
                                  }}
                                />
                              ) : (
                                <video
                                  src={filePreview}
                                  style={{
                                    height: 300,
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                    borderRadius: 4,
                                  }}
                                />
                              ))}
                          </Dragger>
                        ) : !!story?.image ? (
                          <img
                            src={story.image}
                            alt={''}
                            style={{
                              height: 300,
                              maxWidth: '100%',
                              objectFit: 'contain',
                              borderRadius: 4,
                            }}
                          />
                        ) : (
                          <video
                            src={story?.video ?? ''}
                            style={{
                              height: 300,
                              maxWidth: '100%',
                              objectFit: 'contain',
                              borderRadius: 4,
                            }}
                            controls
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ display: 'grid' }} span={24}>
                    <Text fontSize={12} color={'black5'}>
                      Content
                    </Text>
                    {!!storyToEdit?.id ? (
                      <Input.TextArea
                        style={{ marginTop: 4 }}
                        value={storyToEdit?.content ?? story?.content ?? ''}
                        onChange={(e) =>
                          StoryToEdit({
                            ...storyToEdit,
                            content: e.target.value,
                          })
                        }
                        autoSize={{ minRows: 4, maxRows: 6 }}
                      />
                    ) : (
                      <Text fontSize={16}>{story?.content ?? '-'}</Text>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
}
