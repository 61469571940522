import { Col, Input, message, Modal, Row } from 'antd';
import { PrimaryButton, Text } from '../../../../components';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationRejectUserArgs,
  REJECT_USER,
} from '../../../../graphql';

export type RejectionType = 'REJECT' | 'BLOCK';

type Props = {
  handleClose: () => void;
  refetchUsers: () => void;
  type: RejectionType;
  userId: string;
};

const RejectUserModal = ({
  handleClose,
  type,
  refetchUsers,
  userId,
}: Props) => {
  const [reason, setReason] = useState<string>('');
  const [rejectUser, { loading }] = useMutation<
    Mutation,
    MutationRejectUserArgs
  >(REJECT_USER);

  const handleReject = () => {
    rejectUser({
      variables: {
        userId: userId ?? '',
        type,
        reason,
      },
    }).then(() => {
      message.success(
        `User has been ${type === 'REJECT' ? 'rejected' : 'blocked'}!`
      );
      refetchUsers();
      handleClose();
    });
  };

  return (
    <Modal open centered title={null} footer={null} onCancel={handleClose}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500} color={'black10'}>
            {`Reason for ${type === 'REJECT' ? 'rejection' : 'block'}`}
          </Text>
        </Col>
        <Col span={24}>
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 6 }}
            placeholder={'Type reason here...'}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <PrimaryButton
            block
            loading={loading}
            onClick={handleReject}
            disabled={!reason}
          >
            Submit
          </PrimaryButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default RejectUserModal;
