import { Card, Col, Row } from 'antd';
import { Text } from '../Text';
import { Story } from '../../graphql';
import { colors } from '../../utils';

type Props = {
  story: Story;
  edit?: boolean;
  selected?: boolean;
  onClick?: (id: string) => void;
};

export default function StoryItem({ story, edit, selected, onClick }: Props) {
  return (
    <Card
      onClick={edit ? () => onClick?.(story.id) : undefined}
      className={`card-no-padding ${edit && !selected ? 'hoverable-card' : ''}`}
      style={{
        height: '100%',
        borderRadius: 12,
        ...(selected && { border: `2px solid ${colors.blue6}` }),
        ...(edit && { cursor: 'pointer' }),
      }}
    >
      <Row>
        <Col span={24}>
          {story.image ? (
            <img
              src={story?.image}
              alt={''}
              style={{
                width: '100%',
                aspectRatio: 2,
                objectFit: 'cover',
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            />
          ) : story.video ? (
            <video
              src={story?.video}
              style={{
                width: '100%',
                aspectRatio: 2,
                objectFit: 'cover',
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            />
          ) : (
            <></>
          )}
        </Col>
        <Col span={24} style={{ padding: 10, gap: 6, display: 'grid' }}>
          <Text variant={'baseStrong'}>
            {story.user.firstName + ' ' + story.user.lastName}
          </Text>
          <Text variant={'baseNormal'}>
            {story.content.length > 70
              ? `${story.content.slice(0, 70)}...`
              : story.content}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
