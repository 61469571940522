import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { RootState } from '../store';
import { LoggedInUser, User } from '../../graphql';

type UserState = {
  user: User | null;
  isAuthenticated: boolean;
  token: string | null;
  refreshToken: string | null;
};

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
  token: null,
  refreshToken: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem('token', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      return state;
    },
    setUser: (state, action: PayloadAction<UserState['user']>) => {
      if (!isEqual(state.user, action.payload)) {
        state.user = action.payload;
      }
    },
    logout: (state) => {
      localStorage.clear();
      LoggedInUser(undefined);
      state = initialState;
      return state;
    },
  },
});

export const { setToken, setUser, logout } = userSlice.actions;

export const userState = (state: RootState) => state.user;

export default userSlice.reducer;
