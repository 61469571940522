import { useCallback, useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import {
  ErrorButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  APPROVE_USER,
  GET_ALL_USERS,
  GET_USER_BY_ID,
  MANUALLY_VERIFY_USER,
  Mutation,
  MutationApproveUserArgs,
  MutationManuallyVerifyUserArgs,
  MutationUpdateUserInformationArgs,
  MutationUploadFileArgs,
  UPDATE_USER_INFORMATION,
  UPLOAD_FILE,
  uploadClient,
  User,
  UserRole,
  UserStatus,
  UserToEdit,
} from '../../../../graphql';
import { RejectionType } from './RejectUserModal';
import { DeleteUserModal, RejectUserModal } from './index';
import { UserToEdit as TUserToEdit, UserUpdate } from './types';

type Props = { user: User; refetchUser: () => void };

const UserActions = ({ user, refetchUser }: Props) => {
  const userToEdit = useReactiveVar(UserToEdit);
  const [reject, setReject] = useState<RejectionType | null>(null);
  const [deleteAccount, setDeleteAccount] = useState<boolean>(false);
  const [approveUser, { loading: approving }] = useMutation<
    Mutation,
    MutationApproveUserArgs
  >(APPROVE_USER);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [updateUser, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateUserInformationArgs
  >(UPDATE_USER_INFORMATION);
  const [manuallyVerifyUser, { loading: verifying }] = useMutation<
    Mutation,
    MutationManuallyVerifyUserArgs
  >(MANUALLY_VERIFY_USER);

  const handleApprove = () => {
    approveUser({
      variables: {
        userId: user.id,
      },
      refetchQueries: [
        { query: GET_ALL_USERS },
        { query: GET_USER_BY_ID, variables: { userId: user.id } },
      ],
    }).then(() => {
      message.success('User has been approved!');
    });
  };

  const handleManuallyVerify = () => {
    manuallyVerifyUser({
      variables: {
        userId: user.id,
      },
      refetchQueries: [
        { query: GET_ALL_USERS },
        { query: GET_USER_BY_ID, variables: { userId: user.id } },
      ],
    }).then(() => {
      message.success('User has been verified!');
    });
  };

  const handleUpdate = (userToEdit: UserUpdate) => {
    updateUser({
      variables: {
        userId: user.id,
        update: {
          ...(userToEdit?.name && {
            firstName: userToEdit.name.split(' ')[0],
            lastName: userToEdit.name.split(' ')[1] || '',
          }),
          ...(userToEdit?.email &&
            userToEdit.email !== user.email && {
              email: userToEdit.email,
            }),
          ...(userToEdit?.profile && { profile: userToEdit.profile }),
          ...(userToEdit?.role &&
            userToEdit.role !== user.role && { role: userToEdit.role }),
        },
      },
      refetchQueries: [
        { query: GET_ALL_USERS },
        { query: GET_USER_BY_ID, variables: { userId: user.id } },
      ],
    }).then(() => {
      handleCancelEdit();
      message.success('User information has been updated!');
    });
  };

  const handleSaveEdit = () => {
    if (!!userToEdit) {
      if (userToEdit.profile?.picture) {
        uploadFile({ variables: { file: userToEdit.profile?.picture } }).then(
          (pictureRes) => {
            if (userToEdit.profile?.banner) {
              uploadFile({
                variables: { file: userToEdit.profile?.banner },
              }).then((bannerRes) => {
                handleUpdate({
                  ...userToEdit,
                  profile: {
                    ...userToEdit?.profile,
                    picture: pictureRes.data?.uploadFile,
                    banner: bannerRes.data?.uploadFile,
                  },
                });
              });
            } else {
              handleUpdate({
                ...userToEdit,
                profile: {
                  ...userToEdit?.profile,
                  picture: pictureRes.data?.uploadFile,
                },
              } as UserUpdate);
            }
          }
        );
      } else if (userToEdit.profile?.banner) {
        uploadFile({ variables: { file: userToEdit.profile?.banner } }).then(
          (bannerRes) => {
            handleUpdate({
              ...userToEdit,
              profile: {
                ...userToEdit?.profile,
                banner: bannerRes.data?.uploadFile,
              },
            } as UserUpdate);
          }
        );
      } else handleUpdate(userToEdit as UserUpdate);
    }
  };

  const handleReject = () => {
    setReject('REJECT');
  };

  const handleBlock = () => {
    setReject('BLOCK');
  };

  const handleDelete = () => {
    setDeleteAccount(true);
  };

  const handleEdit = () => {
    let toEdit: TUserToEdit = { id: user.id };

    if (user.role === UserRole.Storyteller) {
      if (user.profile?.storyteller?.images) {
        toEdit = {
          ...toEdit,
          profile: {
            storyteller: {
              images: user.profile?.storyteller?.images,
            },
          },
        };
      }
      if (user.profile?.storyteller?.videoUrls) {
        toEdit = {
          ...toEdit,
          profile: {
            ...toEdit.profile,
            storyteller: {
              ...toEdit.profile?.storyteller,
              videoUrls: user.profile?.storyteller?.videoUrls,
            },
          },
        };
      }
    }
    if (user.role === UserRole.Advocate) {
      if (user.profile?.advocate?.activities) {
        toEdit = {
          ...toEdit,
          profile: {
            ...toEdit.profile,
            advocate: {
              activities: user.profile?.advocate?.activities,
            },
          },
        };
      }
    }

    UserToEdit(toEdit);
  };

  const handleCancelEdit = () => {
    UserToEdit(null);
  };

  useEffect(() => {
    return () => {
      if (!!userToEdit) handleCancelEdit();
    };
  }, []);

  const pendingVerificationButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <ErrorButton onClick={handleDelete}>
            Delete account permanently
          </ErrorButton>
        </Col>
        <Col>
          <PrimaryButton onClick={handleManuallyVerify} loading={verifying}>
            Verify user manually
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const approvedButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleEdit}>
            Edit account info
          </SecondaryButton>
        </Col>
        <Col>
          <ErrorButton onClick={handleBlock}>Block account</ErrorButton>
        </Col>
      </Row>
    );
  };

  const pendingApprovalButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleEdit}>
            Edit account info
          </SecondaryButton>
        </Col>
        <Col>
          <ErrorButton onClick={handleReject}>Reject</ErrorButton>
        </Col>
        <Col>
          <PrimaryButton onClick={() => handleApprove()} loading={approving}>
            Approve
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const blockedOrRejectedButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <ErrorButton onClick={handleDelete}>
            Delete account permanently
          </ErrorButton>
        </Col>
        <Col>
          <PrimaryButton onClick={() => handleApprove()} loading={approving}>
            Reopen account
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const editButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleCancelEdit}>Cancel</SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            onClick={handleSaveEdit}
            loading={updating || uploading}
          >
            Save changes
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const getButtons = useCallback(() => {
    if (!!userToEdit?.id) {
      return editButtons();
    }
    if (user.status === UserStatus.PendingVerification) {
      return pendingVerificationButtons();
    }
    if (user.status === UserStatus.Approved) {
      return approvedButtons();
    }
    if ([UserStatus.Rejected, UserStatus.Blocked].includes(user.status)) {
      return blockedOrRejectedButtons();
    }
    if (
      [UserStatus.Verified, UserStatus.PendingApproval].includes(user.status)
    ) {
      return pendingApprovalButtons();
    }
  }, [user, userToEdit]);

  return !!user ? (
    <Row>
      {getButtons()}
      {!!reject && (
        <RejectUserModal
          handleClose={() => setReject(null)}
          refetchUsers={refetchUser}
          type={reject}
          userId={user.id}
        />
      )}
      {deleteAccount && (
        <DeleteUserModal
          handleClose={() => setDeleteAccount(false)}
          userId={user.id}
        />
      )}
    </Row>
  ) : null;
};

export default UserActions;
