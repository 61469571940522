import { Col, Modal, Row } from 'antd';
import { Text } from '../../../../components';
import { RejectionType } from './RejectUserModal';

type Props = { reason: string; type: RejectionType; handleClose: () => void };

const RejectionReason = ({ reason, type, handleClose }: Props) => {
  return (
    <Modal open centered title={null} footer={null} onCancel={handleClose}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500} color={'black10'}>
            {`Reason for ${type === 'REJECT' ? 'rejection' : 'block'}`}
          </Text>
        </Col>
        <Col span={24}>
          <Text color={'black5'} style={{ display: 'flex', width: '90%' }}>
            {reason ?? '-'}
          </Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default RejectionReason;
