import { useCallback, useEffect } from 'react';
import { Col, message, Row } from 'antd';
import {
  PrimaryButton,
  ProgressButton,
  SecondaryButton,
} from '../../../../components';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  GET_GROUP_BY_ID,
  GET_GROUPS,
  Group,
  GroupStatus,
  GroupToEdit,
  Mutation,
  MutationUpdateGroupArgs,
  MutationUploadFileArgs,
  UPDATE_GROUP,
  UpdateGroupInput,
  UPLOAD_FILE,
  uploadClient,
} from '../../../../graphql';

type Props = { group: Group };

export default function GroupActions({ group }: Props) {
  const groupToEdit = useReactiveVar(GroupToEdit);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [updateGroup, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateGroupArgs
  >(UPDATE_GROUP);

  useEffect(() => {
    return () => {
      if (!!groupToEdit) handleCancelEdit();
    };
  }, []);

  const handleEdit = () => {
    GroupToEdit({ id: group.id });
  };

  const handleCancelEdit = () => {
    GroupToEdit(null);
  };

  const handleUpdate = (update: UpdateGroupInput) => {
    updateGroup({
      variables: {
        groupId: group.id,
        update,
      },
      refetchQueries: [
        { query: GET_GROUPS },
        { query: GET_GROUP_BY_ID, variables: { groupId: group.id } },
      ],
    }).then(() => {
      handleCancelEdit();
      message.success('Group has been updated!');
    });
  };

  const handleSaveEdit = () => {
    const update = {
      ...(!!groupToEdit?.name && { name: groupToEdit?.name }),
      ...(!!groupToEdit?.description && {
        description: groupToEdit?.description,
      }),
    };

    if (!!groupToEdit?.file) {
      uploadFile({ variables: { file: groupToEdit.file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleUpdate({ ...update, banner: fileString });
      });
    } else {
      handleUpdate(update);
    }
  };

  const standardButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleEdit}>Edit group</SecondaryButton>
        </Col>
        <Col>
          {group.status === GroupStatus.Archived ? (
            <PrimaryButton
              onClick={() => handleUpdate({ status: GroupStatus.Published })}
              loading={updating}
            >
              Publish group
            </PrimaryButton>
          ) : (
            <ProgressButton
              onClick={() => handleUpdate({ status: GroupStatus.Archived })}
              loading={updating}
            >
              Archive group
            </ProgressButton>
          )}
        </Col>
      </Row>
    );
  };

  const editButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleCancelEdit}>Cancel</SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            onClick={handleSaveEdit}
            loading={uploading || updating}
          >
            Save changes
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const getButtons = useCallback(() => {
    if (!!groupToEdit?.id) {
      return editButtons();
    }
    return standardButtons();
  }, [group, groupToEdit]);

  return !!group ? <Row>{getButtons()}</Row> : null;
}
