import { Card, Col, Row, Table, Tag } from 'antd';
import { Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Event,
  Query,
  Group,
  EventStatus,
  GET_EVENTS,
  QueryGetEventsArgs,
} from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { useState } from 'react';
import { format } from 'date-fns';

export default function Events() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const { data, loading } = useQuery<Query, QueryGetEventsArgs>(GET_EVENTS, {
    variables: { page, take: 10 },
  });

  const columns: ColumnsType<Event> = [
    {
      key: 'title',
      title: 'Event title',
      dataIndex: 'title',
    },
    {
      key: 'start',
      title: 'Event date',
      dataIndex: 'start',
      render: (item) => format(new Date(Number(item)), 'PP'),
    },
    {
      key: 'owner',
      title: 'Creator',
      dataIndex: 'owner.firstName',
      render: (item, record) =>
        record.owner.firstName + ' ' + record.owner.lastName,
    },
    {
      key: 'membersCount',
      title: 'Members count',
      dataIndex: 'membersCount',
    },
    {
      key: 'status',
      title: 'Visibility',
      dataIndex: 'status',
      render: (item) => (
        <Tag color={item === EventStatus.Published ? 'blue' : 'orange'}>
          {startCase(item.toLowerCase())}
        </Tag>
      ),
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500}>
            All events
          </Text>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={loading}
            columns={columns}
            dataSource={data?.getEvents?.data}
            rowClassName={'clickable'}
            onRow={(record) => ({
              onClick: () => navigate(`/events/${record.id}`),
            })}
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 10,
              total: data?.getEvents?.count,
              onChange: (page) => setPage(page),
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
