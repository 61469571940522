import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import 'antd/dist/reset.css';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import './assets/scss/App.scss';
import { Main } from './pages';
import { breakpoints, colors } from './utils';
import { mainnet, bsc, sepolia } from 'wagmi/chains';
import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { GqClient } from './graphql';

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Main />} />)
);

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [sepolia, mainnet],
  [
    infuraProvider({ apiKey: '9fa48baf2add4b30a818580770d18dd6' }),
    publicProvider(),
  ]
);

const config = createConfig({
  autoConnect: false,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: 'ac6818d713537310b1b5435748cba3f4',
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

function App() {
  const { primary50, success, warning, error, secondary400 } = colors;
  const { screenXS, screenSM, screenMD, screenLG, screenXL, screenXXL } =
    breakpoints;

  return (
    <div className="App">
      <WagmiConfig config={config}>
        <ApolloProvider client={GqClient}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: primary50,
                colorText: 'black',
                colorSuccess: success,
                colorError: error,
                colorWarning: warning,
                colorTextDisabled: colors.black6,
                screenXS,
                screenXSMin: screenXS,
                screenXSMax: screenSM - 1,
                screenSM,
                screenSMMin: screenSM,
                screenSMMax: screenMD - 1,
                screenMD,
                screenMDMin: screenMD,
                screenMDMax: screenLG - 1,
                screenLG,
                screenLGMin: screenLG,
                screenLGMax: screenXL - 1,
                screenXL,
                screenXLMin: screenXL,
                screenXLMax: screenXXL - 1,
                screenXXL,
                screenXXLMin: screenXXL,
              },
              components: {
                Card: {
                  colorBgContainer: colors.white,
                  colorBorder: colors.black1,
                  colorBorderSecondary: colors.black1,
                  borderRadius: 12,
                  borderRadiusLG: 12,
                  paddingLG: 24,
                },
                Carousel: {
                  dotWidth: 4,
                  dotHeight: 4,
                  dotActiveWidth: 4,
                },
                Select: {
                  fontFamily: 'Poppins',
                  colorBgContainer: colors.black1,
                  colorBorder: colors.black1,
                  colorTextPlaceholder: colors.black5,
                  colorText: colors.black10,
                  borderRadius: 8,
                  colorIcon: colors.black5,
                  colorIconHover: colors.black7,
                  controlHeight: 42,
                  fontSize: 14,
                  padding: 10,
                  //@ts-ignore
                  activeBg: colors.black1,
                  hoverBg: colors.black1,
                  activeShadow: 'none',
                  colorPrimaryHover: colors.black1,
                  colorPrimaryActive: colors.black1,
                  colorPrimaryBorder: colors.black1,
                  colorPrimary: colors.black1,
                },
                DatePicker: {
                  colorTextLightSolid: colors.black10,
                  colorTextPlaceholder: colors.black5,
                  colorText: colors.black10,
                  borderRadius: 8,
                  colorIcon: colors.black5,
                  colorIconHover: colors.black7,
                  colorTextDisabled: colors.black3,
                  controlHeight: 42,
                  fontSize: 14,
                  padding: 10,
                  activeShadow: 'none',
                  colorPrimaryHover: colors.black1,
                  colorPrimaryActive: colors.black1,
                  colorPrimaryBorder: colors.black1,
                  colorPrimary: colors.black1,
                },
                Input: {
                  fontFamily: 'Poppins',
                  colorBgContainer: colors.black1,
                  colorBorder: colors.black1,
                  colorTextPlaceholder: colors.black5,
                  colorText: colors.black10,
                  borderRadius: 8,
                  activeBorderColor: colors.black1,
                  hoverBorderColor: colors.black1,
                  colorPrimaryBorderHover: colors.primary3,
                  colorIcon: colors.black5,
                  colorIconHover: colors.black7,
                  controlHeight: 42,
                  fontSize: 14,
                  padding: 10,
                  //@ts-ignore
                  activeBg: colors.black1,
                  hoverBg: colors.black1,
                  activeShadow: 'none',
                  colorPrimaryHover: colors.black1,
                  colorPrimaryActive: colors.black1,
                  colorPrimaryBorder: colors.black1,
                  colorPrimary: colors.black1,
                },
                InputNumber: {
                  activeBorderColor: colors.primary,
                  hoverBorderColor: colors.primary,
                  colorBgContainer: 'transparent',
                  colorBorder: colors.black5,
                  colorText: colors.black,
                  fontSize: 16,
                  paddingBlock: 11,
                  fontFamily: 'Poppins',
                },
                Menu: {
                  itemSelectedBg: colors.primary,
                  itemSelectedColor: colors.white,
                  fontSize: 14,
                  fontFamily: 'Poppins',
                  iconSize: 24,
                  borderRadius: 4,
                  itemBorderRadius: 4,
                  iconMarginInlineEnd: 8,
                  itemMarginInline: 0,
                },
                Form: {
                  itemMarginBottom: 12,
                },
                Alert: {
                  colorErrorBg: '#2A1215',
                  colorErrorBorder: '#58181C',
                  colorIcon: '#6E6E6E',
                },
                Checkbox: {
                  colorBorder: colors.black5,
                  colorPrimary: colors.primary,
                  colorPrimaryHover: colors.primary,
                  colorBgContainer: 'transparent',
                  colorIcon: colors.primary,
                },
                Segmented: {
                  borderRadius: 4,
                  itemSelectedBg: 'transparent',
                  colorBgLayout: colors.background,
                  itemSelectedColor: colors.primary,
                  itemColor: 'white',
                  motionDurationMid: '0s',
                  motionDurationSlow: '0.1s',
                  controlPaddingHorizontal: 8,
                  colorBorder: 'red',
                },
                Modal: {
                  contentBg: colors.white,
                  headerBg: colors.white,
                  colorBorder: colors.white,
                  colorPrimaryBorder: colors.white,
                  borderRadius: 12,
                  borderRadiusLG: 12,
                  titleFontSize: 24,
                  colorIcon: colors.black5,
                  padding: 24,
                  paddingContentHorizontalLG: 24,
                  paddingLG: 24,
                  paddingMD: 24,
                  paddingXS: 24,
                  fontWeightStrong: 400,
                },
                Spin: {
                  colorPrimary: colors.primary,
                },
                Radio: {
                  colorBgContainer: 'transparent',
                  buttonCheckedBg: colors.primary,
                  buttonSolidCheckedColor: 'black',
                  buttonSolidCheckedBg: colors.white,
                  buttonSolidCheckedHoverBg: colors.white,
                  colorBorder: colors.borderColor,
                  colorTextDisabled: colors.black5,
                  controlHeight: 48,
                  fontSize: 16,
                  colorPrimary: colors.primary,
                },
                Upload: {
                  colorBorder: '#333',
                },
                Avatar: {
                  colorTextPlaceholder: colors.black5,
                },
                Divider: {
                  colorSplit: colors.black1,
                  verticalMarginInline: 4,
                },
                Table: {
                  headerBg: colors.primary,
                  borderRadius: 12,
                  borderRadiusLG: 12,
                  borderRadiusOuter: 12,
                  rowHoverBg: colors.background,
                  rowSelectedBg: colors.primary1,
                  rowSelectedHoverBg: colors.primary1,
                  headerBorderRadius: 0,
                  borderColor: colors.black1,
                  headerSplitColor: colors.black1,
                  bodySortBg: colors.background,
                  headerSortActiveBg: colors.background,
                  colorBgContainer: colors.white,
                  controlItemBgHover: colors.primary1,
                  headerColor: colors.white,
                },
                Pagination: {
                  itemActiveBg: colors.background,
                  itemBg: colors.background,
                  colorPrimaryBorder: colors.primary,
                  colorBorder: colors.black4,
                },
                Tag: {
                  defaultBg: colors.black4,
                  defaultColor: 'white',
                  colorBorder: colors.black5,
                },
                Tabs: {
                  cardBg: 'transparent',
                  itemSelectedColor: colors.primary,
                  colorBgContainer: colors.black2,
                  lineWidth: 0,
                  colorPrimaryHover: colors.black10,
                  colorPrimaryActive: colors.black10,
                  margin: 0,
                },
              },
            }}
          >
            <RouterProvider router={router} />
          </ConfigProvider>
        </ApolloProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
