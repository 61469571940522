import React, { useState } from 'react';
import { PrimaryButton, Text } from '../../components';
import { Input, Form, Row, Col, message } from 'antd';
import AuthLayout from './AuthLayout';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../api';

type FieldType = {
  email: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: FieldType) => {
    setLoading(true);
    const data = new FormData();
    data.append('email', values.email);

    forgotPassword(data)
      .then(() => {
        setLoading(false);
        message.success('Email sent!');
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
        console.log('err login', err);
      });
  };

  return (
    <AuthLayout flip>
      <Form
        name="basic"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Row gutter={[0, 4]}>
              <Col span={24}>
                <Text fontSize={24} fontWeight={600}>
                  Password recovery
                </Text>
              </Col>
              <Col span={24}>
                <Text fontWeight={500}>
                  Enter your email and we will send you a link to reset your
                  password.
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="email"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input required type="email" placeholder="Email address" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <PrimaryButton
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    marginTop: '0.5em',
                  }}
                >
                  Send reset link
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default ForgotPassword;
