import { Card, Col, Input, message, Pagination, Row, Spin } from 'antd';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  FEATURE_HOMEPAGE,
  FeatureItemsInput,
  GET_EVENTS,
  GET_FEATURED_ITEMS,
  GET_GROUPS,
  GET_STORIES,
  HomepageToEdit,
  Mutation,
  MutationFeatureHomepageArgs,
  Query,
  QueryGetEventsArgs,
  QueryGetGroupsArgs,
  QueryGetStoriesArgs,
  StoryStatus,
} from '../../../graphql';
import { PrimaryButton, SecondaryButton, Text } from '../../../components';
import { EventItem, GroupItem, StoryItem } from '../../../components/common';
import { useEffect, useState } from 'react';

export default function Homepage() {
  const homepageToEdit = useReactiveVar(HomepageToEdit);
  const { data, loading, refetch } = useQuery<Query>(GET_FEATURED_ITEMS);
  const [selectedStories, setSelectedStories] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [storiesPage, setStoriesPage] = useState<number>(1);
  const [groupsPage, setGroupsPage] = useState<number>(1);
  const [eventsPage, setEventsPage] = useState<number>(1);
  const { data: storiesData, loading: storiesLoading } = useQuery<
    Query,
    QueryGetStoriesArgs
  >(GET_STORIES, {
    variables: {
      page: storiesPage,
      take: 15,
      status: StoryStatus.Published,
    },
  });
  const { data: groupsData, loading: groupsLoading } = useQuery<
    Query,
    QueryGetGroupsArgs
  >(GET_GROUPS, {
    variables: { page: groupsPage, take: 15 },
  });
  const { data: eventsData, loading: eventsLoading } = useQuery<
    Query,
    QueryGetEventsArgs
  >(GET_EVENTS, {
    variables: { page: eventsPage, take: 15, type: 'UPCOMING' },
  });
  const [setFeaturedItems, { loading: submitting }] = useMutation<
    Mutation,
    MutationFeatureHomepageArgs
  >(FEATURE_HOMEPAGE);

  useEffect(() => {
    if (data?.getFeaturedItems) {
      setSelectedGroups(
        data?.getFeaturedItems?.groups?.map((group) => group.id) ?? []
      );
      setSelectedEvents(
        data?.getFeaturedItems?.events?.map((event) => event.id) ?? []
      );
      setSelectedStories(
        data?.getFeaturedItems?.stories?.map((story) => story.id) ?? []
      );
    }
  }, [data?.getFeaturedItems]);

  useEffect(() => {
    if (edit) {
      setStoriesPage(1);
      setGroupsPage(1);
      setEventsPage(1);

      HomepageToEdit({
        hero: {
          heading: data?.getFeaturedItems?.hero.heading,
          description: data?.getFeaturedItems?.hero.description,
          buttonText: data?.getFeaturedItems?.hero.buttonText,
          buttonUrl: data?.getFeaturedItems?.hero.buttonUrl,
        },
        stories: selectedStories,
        groups: selectedGroups,
        events: selectedEvents,
      });
    }
  }, [edit]);

  const handleSubmit = () => {
    setFeaturedItems({
      variables: {
        featuredItems: {
          storyIds: homepageToEdit?.stories,
          groupIds: homepageToEdit?.groups,
          eventIds: homepageToEdit?.events,
          hero: {
            ...homepageToEdit?.hero,
            ...(homepageToEdit?.hero?.buttonUrl && {
              buttonUrl:
                homepageToEdit?.hero?.buttonUrl[0] === '/'
                  ? homepageToEdit?.hero?.buttonUrl
                  : `/${homepageToEdit?.hero?.buttonUrl}`,
            }),
          },
        } as FeatureItemsInput,
      },
    }).then(() => {
      message.success('Featured items have been updated!');
      refetch();
      setEdit(false);
    });
  };

  return loading ? (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  ) : (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <Card>
          <Col span={24}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <Text variant={'xlMedium'}>Homepage</Text>
              </Col>
              <Col>
                <Row gutter={16}>
                  <Col>
                    <SecondaryButton onClick={() => setEdit(!edit)}>
                      {edit ? 'Cancel' : 'Edit homepage'}
                    </SecondaryButton>
                  </Col>
                  {edit && (
                    <Col>
                      <PrimaryButton
                        onClick={handleSubmit}
                        loading={submitting}
                      >
                        Save changes
                      </PrimaryButton>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Text variant={'heading5'}>Hero section information</Text>
            </Row>
            <Row style={{ marginTop: 10, maxWidth: '50%' }} gutter={[0, 20]}>
              <Col span={24} style={{ display: 'grid' }}>
                <Text variant={'smNormal'} color={'black5'}>
                  Title
                </Text>
                {edit ? (
                  <Input
                    style={{ marginTop: 4 }}
                    value={homepageToEdit?.hero?.heading ?? ''}
                    onChange={(e) =>
                      HomepageToEdit({
                        ...homepageToEdit,
                        hero: {
                          ...homepageToEdit?.hero,
                          heading: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <Text variant={'lgNormal'}>
                    {data?.getFeaturedItems.hero.heading ?? '-'}
                  </Text>
                )}
              </Col>
              <Col span={24} style={{ display: 'grid' }}>
                <Text variant={'smNormal'} color={'black5'}>
                  Description
                </Text>
                {edit ? (
                  <Input.TextArea
                    style={{ marginTop: 4 }}
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    value={homepageToEdit?.hero?.description ?? ''}
                    onChange={(e) =>
                      HomepageToEdit({
                        ...homepageToEdit,
                        hero: {
                          ...homepageToEdit?.hero,
                          description: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <Text variant={'lgNormal'}>
                    {data?.getFeaturedItems.hero.description ?? '-'}
                  </Text>
                )}
              </Col>
              <Col span={24} style={{ display: 'grid' }}>
                <Text variant={'smNormal'} color={'black5'}>
                  Button text
                </Text>
                {edit ? (
                  <Input
                    style={{ marginTop: 4 }}
                    value={homepageToEdit?.hero?.buttonText ?? ''}
                    onChange={(e) =>
                      HomepageToEdit({
                        ...homepageToEdit,
                        hero: {
                          ...homepageToEdit?.hero,
                          buttonText: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <Text variant={'lgNormal'}>
                    {data?.getFeaturedItems.hero.buttonText ?? '-'}
                  </Text>
                )}
              </Col>
              <Col span={24} style={{ display: 'grid' }}>
                <Text variant={'smNormal'} color={'black5'}>
                  Button url
                </Text>
                {edit ? (
                  <Input
                    style={{ marginTop: 4 }}
                    value={homepageToEdit?.hero?.buttonUrl ?? ''}
                    onChange={(e) =>
                      HomepageToEdit({
                        ...homepageToEdit,
                        hero: {
                          ...homepageToEdit?.hero,
                          buttonUrl: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <Text variant={'lgNormal'}>
                    {data?.getFeaturedItems.hero.buttonUrl ?? '-'}
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Col span={24}>
            <Row>
              <Text variant={'heading5'}>Groups to show</Text>
            </Row>
            <Row style={{ marginTop: 12 }}>
              <Text variant={'smNormal'} color={'black5'}>
                {edit
                  ? 'Select up to 3 groups you want to display on the homepage.'
                  : 'These groups are currently featured on the homepage'}
              </Text>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 4, ...(groupsLoading && { padding: '6em' }) }}
              justify={groupsLoading ? 'center' : 'start'}
            >
              {edit ? (
                groupsLoading ? (
                  <Spin />
                ) : (
                  groupsData?.getGroups?.data?.map((group) => (
                    <Col style={{ width: '20%' }} key={group.id}>
                      <GroupItem
                        edit
                        onClick={(id) => {
                          if (
                            (homepageToEdit &&
                              homepageToEdit?.groups &&
                              homepageToEdit.groups.length < 3) ||
                            homepageToEdit?.groups?.includes(id)
                          ) {
                            HomepageToEdit({
                              ...homepageToEdit,
                              groups: homepageToEdit?.groups?.includes(id)
                                ? homepageToEdit?.groups?.filter(
                                    (item) => item !== id
                                  )
                                : [...(homepageToEdit?.groups || []), id],
                            });
                          }
                        }}
                        group={group}
                        selected={homepageToEdit?.groups?.includes(group.id)}
                      />
                    </Col>
                  ))
                )
              ) : (
                data?.getFeaturedItems?.groups?.map((group) => (
                  <Col style={{ width: '20%' }} key={group.id}>
                    <GroupItem group={group} />
                  </Col>
                ))
              )}
              {edit && (
                <Col span={24}>
                  <Row justify={'end'}>
                    <Pagination
                      hideOnSinglePage
                      pageSize={15}
                      current={groupsPage}
                      total={groupsData?.getGroups?.count}
                      onChange={(page) => setGroupsPage(page)}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Col span={24}>
            <Row>
              <Text variant={'heading5'}>Events to show</Text>
            </Row>
            <Row style={{ marginTop: 12 }}>
              <Text variant={'smNormal'} color={'black5'}>
                {edit
                  ? 'Select up to 3 events you want to display on the homepage.'
                  : 'These events are currently featured on the homepage'}
              </Text>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 4, ...(eventsLoading && { padding: '6em' }) }}
              justify={eventsLoading ? 'center' : 'start'}
            >
              {edit ? (
                eventsLoading ? (
                  <Spin />
                ) : (
                  eventsData?.getEvents?.data?.map((event) => (
                    <Col style={{ width: '20%' }} key={event.id}>
                      <EventItem
                        edit
                        onClick={(id) => {
                          if (
                            (homepageToEdit &&
                              homepageToEdit?.events &&
                              homepageToEdit.events.length < 3) ||
                            homepageToEdit?.events?.includes(id)
                          ) {
                            HomepageToEdit({
                              ...homepageToEdit,
                              events: homepageToEdit?.events?.includes(id)
                                ? homepageToEdit?.events?.filter(
                                    (item) => item !== id
                                  )
                                : [...(homepageToEdit?.events || []), id],
                            });
                          }
                        }}
                        event={event}
                        selected={homepageToEdit?.events?.includes(event.id)}
                      />
                    </Col>
                  ))
                )
              ) : (
                data?.getFeaturedItems?.events?.map((event) => (
                  <Col style={{ width: '20%' }} key={event.id}>
                    <EventItem event={event} />
                  </Col>
                ))
              )}
              {edit && (
                <Col span={24}>
                  <Row justify={'end'}>
                    <Pagination
                      hideOnSinglePage
                      pageSize={15}
                      current={eventsPage}
                      total={eventsData?.getEvents?.count}
                      onChange={(page) => setEventsPage(page)}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Col span={24}>
            <Row>
              <Text variant={'heading5'}>Stories to show</Text>
            </Row>
            <Row style={{ marginTop: 12 }}>
              <Text variant={'smNormal'} color={'black5'}>
                {edit
                  ? 'Select up to 10 posts you want to display on the homepage.'
                  : 'These stories are currently featured on the homepage'}
              </Text>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{
                marginTop: 4,
                ...(storiesLoading && { padding: '6em' }),
              }}
              justify={storiesLoading ? 'center' : 'start'}
            >
              {edit ? (
                storiesLoading ? (
                  <Spin />
                ) : (
                  storiesData?.getStories?.data?.map((story) => (
                    <Col style={{ width: '20%' }} key={story.id}>
                      <StoryItem
                        edit
                        onClick={(id) => {
                          if (
                            (homepageToEdit &&
                              homepageToEdit?.stories &&
                              homepageToEdit.stories.length < 10) ||
                            homepageToEdit?.stories?.includes(id)
                          ) {
                            HomepageToEdit({
                              ...homepageToEdit,
                              stories: homepageToEdit?.stories?.includes(id)
                                ? homepageToEdit?.stories?.filter(
                                    (item) => item !== id
                                  )
                                : [...(homepageToEdit?.stories || []), id],
                            });
                          }
                        }}
                        story={story}
                        selected={homepageToEdit?.stories?.includes(story.id)}
                      />
                    </Col>
                  ))
                )
              ) : (
                data?.getFeaturedItems?.stories?.map((story) => (
                  <Col style={{ width: '20%' }} key={story.id}>
                    <StoryItem story={story} />
                  </Col>
                ))
              )}
              {edit && (
                <Col span={24}>
                  <Row justify={'end'}>
                    <Pagination
                      hideOnSinglePage
                      pageSize={15}
                      current={storiesPage}
                      total={storiesData?.getStories?.count}
                      onChange={(page) => setStoriesPage(page)}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Card>
      </Col>
    </Row>
  );
}
