import { Card, Col, Row, Table, Tag } from 'antd';
import { Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Query,
  Group,
  QueryGetGroupsArgs,
  GET_GROUPS,
  GroupStatus,
} from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { useState } from 'react';

export default function Groups() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const { data, loading } = useQuery<Query, QueryGetGroupsArgs>(GET_GROUPS, {
    variables: { page, take: 10 },
  });

  const columns: ColumnsType<Group> = [
    {
      key: 'title',
      title: 'Group title',
      dataIndex: 'name',
    },
    {
      key: 'owner',
      title: 'Creator',
      dataIndex: 'owner.firstName',
      render: (_, record) =>
        record.owner.firstName + ' ' + record.owner.lastName,
    },
    {
      key: 'membersCount',
      title: 'Members count',
      dataIndex: 'membersCount',
    },
    {
      key: 'status',
      title: 'Visibility',
      dataIndex: 'status',
      render: (item) => (
        <Tag color={item === GroupStatus.Published ? 'blue' : 'orange'}>
          {startCase(item.toLowerCase())}
        </Tag>
      ),
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500}>
            All groups
          </Text>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={loading}
            columns={columns}
            dataSource={data?.getGroups?.data}
            rowClassName={'clickable'}
            onRow={(record) => ({
              onClick: () => navigate(`/groups/${record.id}`),
            })}
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 10,
              total: data?.getGroups?.count,
              onChange: (page) => setPage(page),
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
