import { useCallback, useEffect } from 'react';
import { Col, message, Row } from 'antd';
import {
  PrimaryButton,
  ProgressButton,
  SecondaryButton,
} from '../../../../components';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  Event,
  GET_EVENT_BY_ID,
  GET_EVENTS,
  EventStatus,
  EventToEdit,
  Mutation,
  MutationUpdateEventArgs,
  MutationUploadFileArgs,
  UPDATE_EVENT,
  UpdateEventInput,
  UPLOAD_FILE,
  uploadClient,
} from '../../../../graphql';

type Props = { event: Event };

export default function EventActions({ event }: Props) {
  const eventToEdit = useReactiveVar(EventToEdit);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [updateEvent, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateEventArgs
  >(UPDATE_EVENT);

  useEffect(() => {
    return () => {
      if (!!eventToEdit) handleCancelEdit();
    };
  }, []);

  const handleEdit = () => {
    EventToEdit({ id: event.id });
  };

  const handleCancelEdit = () => {
    EventToEdit(null);
  };

  const handleUpdate = (update: UpdateEventInput) => {
    updateEvent({
      variables: {
        eventId: event.id,
        update,
      },
      refetchQueries: [
        { query: GET_EVENTS },
        { query: GET_EVENT_BY_ID, variables: { eventId: event.id } },
      ],
    }).then(() => {
      handleCancelEdit();
      message.success('Event has been updated!');
    });
  };

  const handleSaveEdit = () => {
    const update = {
      ...(!!eventToEdit?.title && { name: eventToEdit?.title }),
      ...(!!eventToEdit?.location &&
        eventToEdit.location.placeId !== event.location.placeId && {
          location: eventToEdit?.location,
        }),
      ...(!!eventToEdit?.location && { location: eventToEdit?.location }),
      ...(!!eventToEdit?.start && { start: eventToEdit?.start }),
      ...(!!eventToEdit?.end && { end: eventToEdit?.end }),
      ...(!!eventToEdit?.description && {
        description: eventToEdit?.description,
      }),
    } as UpdateEventInput;

    if (!!eventToEdit?.file) {
      uploadFile({ variables: { file: eventToEdit.file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleUpdate({ ...update, banner: fileString });
      });
    } else {
      handleUpdate(update);
    }
  };

  const standardButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleEdit}>Edit event</SecondaryButton>
        </Col>
        <Col>
          {event.status === EventStatus.Archived ? (
            <PrimaryButton
              onClick={() => handleUpdate({ status: EventStatus.Published })}
              loading={updating}
            >
              Publish event
            </PrimaryButton>
          ) : (
            <ProgressButton
              onClick={() => handleUpdate({ status: EventStatus.Archived })}
              loading={updating}
            >
              Archive event
            </ProgressButton>
          )}
        </Col>
      </Row>
    );
  };

  const editButtons = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col>
          <SecondaryButton onClick={handleCancelEdit}>Cancel</SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            onClick={handleSaveEdit}
            loading={uploading || updating}
          >
            Save changes
          </PrimaryButton>
        </Col>
      </Row>
    );
  };

  const getButtons = useCallback(() => {
    if (!!eventToEdit?.id) {
      return editButtons();
    }
    return standardButtons();
  }, [event, eventToEdit]);

  return !!event ? <Row>{getButtons()}</Row> : null;
}
