import { Text, PrimaryButton } from '../../components';
import { Row, Col } from 'antd';
import { colors } from '../../utils';
import AuthLayout from './AuthLayout';
import { useNavigate } from 'react-router-dom';

const VerifyAccount = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Row gutter={[0, 4]}>
            <Col span={24}>
              <Text fontSize={24} fontWeight={600}>
                Thank you for signing up!
              </Text>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Text fontWeight={500} color={'black7'}>
                    We have sent you an email to verify your account.
                  </Text>
                </Col>
                <Col span={24}>
                  <Text fontWeight={500} color={'black7'}>
                    No email received? Please check your spam.
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <PrimaryButton
            style={{
              width: '100%',
            }}
            onClick={() => navigate('/')}
          >
            Log in
          </PrimaryButton>
        </Col>
      </Row>
    </AuthLayout>
  );
};

export default VerifyAccount;
