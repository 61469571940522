import React, { useEffect, useState } from 'react';
import { colors } from '../utils';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { Location } from '../graphql';
import { AutocompletionRequest } from 'react-google-places-autocomplete/build/types';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

function LocationSearch({
  options = {},
  location,
  setLocation,
}: {
  options?: AutocompletionRequest;
  location: string;
  setLocation: (val: Location) => void;
}) {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (location) {
      setValue(JSON.parse(location));
    }
  }, [location]);

  useEffect(() => {
    if (value?.value?.place_id) {
      geocodeByPlaceId(value?.value.place_id)
        .then((results) => {
          setLocation({
            placeId: value?.value.place_id,
            title: value?.value.structured_formatting.main_text,
            description: value?.value.structured_formatting.secondary_text,
            lat: results[0].geometry.location.lat(),
            long: results[0].geometry.location.lng(),
            locationString: JSON.stringify(value),
          });
        })
        .catch((error) => console.error(error));
    }
  }, [value]);

  return (
    <GooglePlacesAutocomplete
      autocompletionRequest={options}
      apiKey={GOOGLE_MAPS_API_KEY}
      selectProps={{
        styles: {
          control: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: colors.white,
            border: `1px solid ${colors.defaultBorderColor}`,
            boxShadow: 'none',
            borderRadius: 8,
            height: 42,
            ':focus': {
              border: `1px solid ${colors.defaultBorderColor}`,
            },
            ':hover': {
              border: `1px solid ${colors.defaultBorderColor}`,
            },
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: colors.white,
            borderRadius: 8,
            border: `1px solid ${colors.defaultBorderColor}`,
            boxShadow: `2px 0px 6px 0px ${colors.defaultBorderColor}`,
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: colors.white,
            borderRadius: 8,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? colors.black1 : colors.white,
            color: colors.black10,
            ':hover': {
              backgroundColor: colors.black1,
            },
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: colors.black5,
          }),
        },
        value,
        onChange: setValue,
      }}
    />
  );
}

export default LocationSearch;
