import { useEffect } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { colors } from '../../utils';
import {
  Event,
  Events,
  Group,
  Groups,
  Homepage,
  Stories,
  Story,
  User,
  Users,
} from '../dashboard';
import {
  Login,
  SignUp,
  ResetPassword,
  ForgotPassword,
  VerifyAccount,
} from '../auth';
import { useDispatch, useSelector } from 'react-redux';
import { appState, toggleSidebar, userState } from '../../redux/reducers';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Header } from '../../components';

const { Content } = Layout;

const Main = () => {
  const user = useSelector(userState);

  useEffect(() => {
    console.log('User>> ', user);
  }, []);

  return user.token ? <LoggedInRoutes /> : <NotLoggedInRoutes />;
};

const LoggedInRoutes = () => {
  const dispatch = useDispatch();
  const { sidebarVisible } = useSelector(appState);
  const { md, lg, xl } = useBreakpoint();

  useEffect(() => {
    if (md && !sidebarVisible) {
      dispatch(toggleSidebar());
    }
  }, [md]);

  const getLeftMargin = () => {
    // if (xl) return 325;
    // if (lg) return 300;
    // if (md) return 280;
    return 0;
  };

  return (
    <Layout
      hasSider
      style={{ minHeight: '100vh', backgroundColor: colors.background }}
    >
      {/*{sidebarVisible && <Sidebar />}*/}
      <Layout
        style={{
          marginLeft: getLeftMargin(),
          // ...(!md && sidebarVisible && { filter: 'blur(1.5px)' }),
          gap: 16,
          padding: 16,
        }}
      >
        <Header />
        <Content>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  colorBgContainer: colors.white,
                  colorBorder: colors.defaultBorderColor,
                  activeBorderColor: colors.defaultBorderColor,
                  hoverBg: colors.white,
                  activeBg: colors.white,
                },
                Select: {
                  colorBgContainer: colors.white,
                  colorBorder: colors.defaultBorderColor,
                },
              },
            }}
          >
            <Routes>
              <Route path={'/'} element={<Users />} />
              <Route path={'/users'} element={<Users />} />
              <Route path={'/users/:id'} element={<User />} />
              <Route path={'/stories'} element={<Stories />} />
              <Route path={'/stories/:id'} element={<Story />} />
              <Route path={'/groups'} element={<Groups />} />
              <Route path={'/groups/:id'} element={<Group />} />
              <Route path={'/events'} element={<Events />} />
              <Route path={'/events/:id'} element={<Event />} />
              <Route path={'/homepage'} element={<Homepage />} />
              <Route path={'*'} element={<Users />} />
            </Routes>
          </ConfigProvider>
        </Content>
      </Layout>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout style={{ minHeight: '100vh', background: colors.background }}>
      <Routes>
        <Route path={'/login'} element={<Login />} />
        <Route path={'/register'} element={<SignUp />} />
        <Route path={'/verify-account'} element={<VerifyAccount />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/reset-password'} element={<ResetPassword />} />
        <Route path={'*'} element={<Login />} />
      </Routes>
    </Layout>
  );
};

export default Main;
