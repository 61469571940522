import { Card, Col, Row } from 'antd';
import { Text } from '../Text';
import { Group } from '../../graphql';
import { colors } from '../../utils';

type Props = {
  group: Group;
  edit?: boolean;
  selected?: boolean;
  onClick?: (id: string) => void;
};

export default function GroupItem({ group, selected, edit, onClick }: Props) {
  return (
    <Card
      onClick={edit ? () => onClick?.(group.id) : undefined}
      className={`card-no-padding ${edit && !selected ? 'hoverable-card' : ''}`}
      style={{
        height: '100%',
        borderRadius: 12,
        ...(selected && { border: `2px solid ${colors.blue6}` }),
        ...(edit && { cursor: 'pointer' }),
      }}
    >
      <Row>
        <Col span={24}>
          <img
            src={group?.banner}
            alt={''}
            style={{
              width: '100%',
              aspectRatio: 2,
              objectFit: 'cover',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
        </Col>
        <Col span={24} style={{ padding: '12px 16px', display: 'grid' }}>
          <Text variant={'lgStrong'}>{group?.name}</Text>
          <Text variant={'smMedium'} color={'black4'} style={{ marginTop: 4 }}>
            {group?.membersCount} members
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
