import React, { useMemo } from 'react';
import { Text, PrimaryButton } from '../../components';
import { Input, Form, Row, Col, message, Select } from 'antd';
import AuthLayout from './AuthLayout';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationRegisterArgs,
  UserRole,
  REGISTER,
} from '../../graphql';
import { colors } from '../../utils';

type Role = 'STORYTELLER' | 'GUARDIAN' | 'ADVOCATE' | 'AMBASSADOR';
type FieldType = {
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword?: string;
};

const SignUp = () => {
  const navigate = useNavigate();
  const roles: Role[] = useMemo(
    () => ['STORYTELLER', 'GUARDIAN', 'ADVOCATE', 'AMBASSADOR'],
    []
  );
  const [register, { loading }] = useMutation<Mutation, MutationRegisterArgs>(
    REGISTER
  );

  const onFinish = async (values: FieldType) => {
    try {
      const { firstName, lastName, role, email, password } = values;
      const res = await register({
        variables: {
          userData: {
            firstName,
            lastName,
            role,
            email,
            password,
          },
        },
      });
      if (res.data?.register) {
        message.success('User registered successfully!');
        navigate('/');
      }
    } catch (error) {
      console.log('err register', error);
    }
  };

  return (
    <AuthLayout>
      <Form
        name="basic"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Row gutter={[0, 4]}>
              <Col span={24}>
                <Text fontSize={24} fontWeight={600}>
                  Welcome to Ocean Life
                </Text>
              </Col>
              <Col span={24}>
                <Row align={'middle'} justify={'center'} gutter={4}>
                  <Col>
                    <Text fontWeight={500} color={'black7'}>
                      Already have an account?
                    </Text>
                  </Col>
                  <Col>
                    <Link to={'/'}>
                      <Text fontWeight={500} color={'primary'}>
                        Log in
                      </Text>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item<FieldType>
                  name="firstName"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input required placeholder="First name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<FieldType>
                  name="lastName"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input required placeholder="Last name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="role"
                  rules={[{ required: true, message: '' }]}
                >
                  <Select
                    style={{ textAlign: 'left', borderColor: colors.black5 }}
                    placeholder={'Role'}
                    onChange={(val) => console.log('select', val)}
                  >
                    {roles.map((role) => (
                      <Select.Option value={role} key={role}>
                        {role[0] + role.slice(1).toLowerCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="email"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input required type="email" placeholder="Email address" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="password"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input.Password required placeholder="Password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(''));
                      },
                    }),
                  ]}
                >
                  <Input.Password required placeholder="Confirm password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <PrimaryButton
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    marginTop: '0.5em',
                  }}
                >
                  Create account
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default SignUp;
